<template>
    <div class="section customs">
        
        <div class="image-content" id="image-content">
            <div class="canvas-container">
                <canvas ref="myElement" id="video" class="video" />
            </div>
            
            <div class="top">
                
                <button class="button" v-if="language=='fr'" @click="$router.push({ name: 'collection' })">Personnaliser</button>
                <button class="button" v-else @click="$router.push({ name: 'collection' })">Customize</button>
            </div>
            <img class="round" src="../../../../public/assets/images/customizeround.png"  />
        </div>

    </div>
</template>

<script>

import ScrollMagic  from 'ScrollMagic';
import jQuery from 'jquery';
import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

export default {
    name: "LandingCustomizationsComponent",
    props: ['language'],
    data(){
        return {
            controller:null,
            scene:null,
            lastScrollY:0,
            currentTimeVideo:0,
            scene2:null,
            animationInit:false,
            canvas:null,
            context:null,
            frameCount:89,
            images : [],
            airpods : {
                frame: 0
            },
            img:null,
        }
    },
    
    methods: {
        currentFrame(index){
           return require(`../../../../public/assets/images/cubes/personalization_textures_colors_materials_visages-${(index + 1).toString().padStart(2, '0')}.jpg`)
        },
        handleScroll(){
            if(window.scrollY>3000)
            {

                if(this.animationInit==false)
                {
                    this.initAnimation()
                }
            }
        },
        handleResize(){
            if(window.innerWidth>1024)
            {


           
                this.canvas.width = window.innerWidth;
                this.canvas.height = window.innerHeight;
                this.render()
            }
        },
        initAnimation()
        {
        
            this.animationInit=true
            gsap.registerPlugin(ScrollTrigger);
            this.canvas = document.getElementById("video");
            this.context = this.canvas.getContext("2d");

            this.canvas.width = window.innerWidth;
            this.canvas.height = window.innerHeight;


            for (let i = 0; i < this.frameCount; i++) {
                this.img = new Image();
                this.img.src = this.currentFrame(i);
                this.images.push(this.img);
            }

            gsap.to(this.airpods, {
            frame: this.frameCount - 1,
            snap: "frame",
            ease: "none",
            scrollTrigger: {
                trigger: ".canvas-container",
                start: "top top",
                end: "+="+window.innerHeight*0.9,
                markers: false,
                pin: false,
                scrub: 0.5
            },
                onUpdate: this.render // use animation onUpdate instead of scrollTrigger's onUpdate
            });

            this.images[0].onload = this.render;
        },
        render() {
            
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            if(window.innerWidth>1024)
            {

                if(this.canvas.width*0.5625<this.canvas.height)
                {
                    this.context.drawImage(this.images[this.airpods.frame], 0, 0,window.innerHeight*1.7777,window.innerHeight); 
                }
                else
                {
                    this.context.drawImage(this.images[this.airpods.frame], 0, 0,window.innerWidth,window.innerWidth*0.5625); 
                }

            }
            else 
            {

                this.context.drawImage(this.images[this.airpods.frame], 0, 0,this.canvas.height*1.7777,this.canvas.height); 
               
            }
            
        }
        
       
    },
    
    mounted() {
        

  


        // jQuery(window).scroll(function(){
        //     let myVideo=document.getElementById('video');
        //     let currentTime=this.currentTimeVideo;
        //     if(currentTime==undefined)
        //     {
        //         currentTime=0
        //     }
        //     //console.log(currentTime)

        //     let videoTop=jQuery('#image-content').offset().top - window.innerHeight
        //     let videoBottom=(jQuery('#image-content').offset().top+jQuery('#image-content').height())

        //     if(window.scrollY>videoTop && window.scrollY<videoBottom) {

        //         console.log('betweeeen')
        //         if(this.lastScrollY>window.scrollY)
        //         {
        //             //to top
        //             currentTime -= 0.01

        //             if(currentTime<0)
        //             {
        //                 currentTime=0
        //             }
        //         }
        //         else {
        //             //to bottom
        //             currentTime += 0.01
        //             if(currentTime>10)
        //             {
        //                 currentTime=10
        //             }
                    
        //         }
        //         console.log(this.currentTimeVideo)

                
        //     }
        //     else {
        //         if(window.scrollY<videoTop)
        //         {
        //             currentTime=0
        //         }
        //         else if(window.scrollY>videoBottom)
        //         {
        //             currentTime=10
        //         }
        //     }
        //     myVideo.currentTime=currentTime
        //     this.currentTimeVideo=currentTime
        //     this.lastScrollY=window.scrollY
            
        //     });

        window.addEventListener('resize', this.handleResize);
        window.addEventListener('scroll', this.handleScroll);
  
    }
}
</script>

<style scoped>
.image-content .video {
    position: absolute;
    left:0;
    top:0;
    min-width:100%;
    height:100%;
}
.image-content .top {
   
    position:absolute;
    top:50%;
    left:0;
    z-index:2;
    width:100%;
    transform: translateY(-50%);
    text-align:center;
   
}

.section.customs {
    position:relative;
    width:100%;
    padding-bottom:150px;
    padding-top:150px;
    background:#fff;
}


.section.customs .image-content{
width:100%;


height:100vh;
background-position:center top;
background-size:100% auto;
position: relative;

overflow: hidden;

background-color:#000;
}
.section.customs .image-background{
    position:absolute;
    width:100%;
    height:auto;
    top:0;
    left:50%;
    border-radius: 39px;
    z-index: 1;
    transform:translateX(-50%);
}

#trigger1{
    position:relative;
    top:-50vh;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.section.customs .round {
    position:absolute;
    animation: rotating 12s linear infinite;
    left:50%;
    top:50%;
    width:400px;
    margin-left:-200px;
    margin-top:-200px;
   
}
.section.customs .button{
    font-size:20px;
    cursor: pointer;
    color:#fff;
    padding: 16px 75px;
 
  
    font-weight: 400;
    text-transform: none;
    white-space: nowrap;
   
    line-height: 1;

    background: transparent linear-gradient(91deg, rgba(192, 192, 192, 1) -50%, rgba(0, 0, 255, 0.9) 100%) 0% 0% no-repeat padding-box;
border-radius: 40px;

    z-index: 2;
  
}
.canvas-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>