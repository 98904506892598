<template>
    <div class="section faq">
   
        <h2 class="title">Frequently asked questions</h2>
        <br /><br />
        <div class="faq-landing">
            <div class="left">
                <img src="../../../../public/assets/images/faqround.png" width="200" />
            </div>
            <div class="refund-faq">
            
                <div class="faq-category" v-if="language=='fr'">

                    <div class="question">Comment VISAGES assure-t-elle la précision des lunettes sur-mesure à distance ?</div>
                    <div class="answer">
                        VISAGES utilise un algorithme de scan 3D propriétaire, enrichi par intelligence artificielle, pour garantir un ajustement parfait de chaque paire de lunettes à distance. Après avoir passé commande sur www.visages.com et téléchargé notre application mobile, un scan 3D de votre visage est réalisé. Cette technologie avancée capture avec une précision médicale, au dixième de millimètre, vos caractéristiques morphologiques et optiques uniques. Cette approche innovante permet de créer des lunettes parfaitement adaptées à votre morphologie, offrant ainsi un confort inégalé et une esthétique sur mesure.
                    </div>
                    <div class="question">Quel est le processus pour commander une paire de lunettes sur-mesure?</div>
                    <div class="answer">
                        <ul>
                            <li >Sélection du modèle :&nbsp;Commencez par choisir votre modèle préféré.</li>
                            <li >Personnalisation :&nbsp;Personnalisez votre paire (couleur, textures, branches, verres, gravures).</li>
                            <li >Sélection sur-mesure :&nbsp;Pour l'option sur-mesure, ajoutez au panier et payez.</li>
                            <li >Scan du visage :&nbsp;Après achat, téléchargez notre application mobile pour effectuer le scan 3D nécessaire pour la mise à la mesure.</li>
                            <li>Livraison :&nbsp;Recevez vos lunettes sur-mesure chez vous en 7 à 15 jours après avoir effectué le scan.</li>
                        </ul>
                    </div>
                    <div class="question">Que faire si je ne suis pas satisfait(e) de mon sur-mesure?</div>
                    <div class="answer">
                        Nous accordons une grande importance à l'ajustement de vos lunettes sur-mesure et à la garantie de notre service de mise à mesure. Nous avons tellement confiance en notre algorithme de mise a la mesure, que si l'ajustement de votre première commande ne vous satisfait pas à 100%, nous nous engageons à fabriquer un nouvel article, à nos frais. Vous avez la possibilité de soumettre votre demande ici, jusqu'à 1 mois après votre achat. Notre priorité est de vous assurer une satisfaction totale avec des lunettes qui vous correspondent parfaitement.
                    </div>
                    <div class="question">Comment fonctionne l’impression 3D?</div>
                    <div class="answer">
                        L'impression 3D est une méthode de fabrication additive qui, contrairement aux techniques traditionnelles soustractives, utilise précisément la matière nécessaire pour chaque paire de lunettes. Cette approche assure une production sans déchet, alignée avec notre engagement pour la durabilité et la responsabilité environnementale. Pour en savoir plus sur notre processus de fabrication par l’impression 3D, <a href="/comment-ca-marche">cliquez ici</a>.
                    </div>

                    <div class="question">Quel est le délai de livraison pour ma commande?</div>
                    <div class="answer">
                        Le délai de livraison de votre commande peut varier en fonction de votre panier et sera indiqué au moment du check-out.
                    </div>



                </div>

                <div class="faq-category" v-else>

                    <div class="question">How does VISAGES ensure the precision of custom glasses remotely?</div>
                    <div class="answer">
                        VISAGES uses a proprietary 3D scanning algorithm, enhanced by artificial intelligence, to guarantee a perfect fit for every pair of glasses remotely. After placing an order on www.visages.com and downloading our mobile app, a 3D scan of your face is performed. This advanced technology captures your unique morphological and optical characteristics with medical precision, down to the tenth of a millimeter. This innovative approach allows us to create glasses perfectly suited to your morphology, offering unparalleled comfort and a custom aesthetic.
                    </div>
                    <div class="question">What is the process for ordering a pair of custom glasses?</div>
                    <div class="answer">
                        <ul>
                            <li >Model selection: Start by choosing your preferred model.</li>
                            <li >Customisation: Customise your pair (color, textures, temples, lenses, engravings).</li>
                            <li >Custom selection: For the custom option, add to cart and pay.</li>
                            <li >Face scan: After purchase, download our mobile app to perform the necessary 3D scan for custom fitting.</li>
                            <li>Delivery: Receive your custom glasses at home.</li>
                        </ul>
                    </div>
                    <div class="question">What if I am not satisfied with my custom-made order?</div>
                    <div class="answer">
                        We place great importance on the fit of your custom-made glasses and the guarantee of our custom-fit service. We have so much confidence in our custom-fit algorithm that if the fit of your first order does not satisfy you 100%, we commit to manufacturing a new item, at our expense. You have the option to submit your request here, up to 1 month after your purchase. Our priority is to ensure your total satisfaction with glasses that fit you perfectly.
                    </div>
                    <div class="question">How does 3D printing work?</div>
                    <div class="answer">
                        3D printing is an additive manufacturing method that, unlike traditional subtractive techniques, uses precisely the necessary material for each pair of glasses. This approach ensures a waste-free production, aligned with our commitment to sustainability and environmental responsibility. To learn more about our 3D printing manufacturing process, <a href="/en/comment-ca-marche">click here</a>.
                    </div>

                    <div class="question">What is the delivery time for my order?</div>
                    <div class="answer">
                        The delivery time for your order may vary depending on your cart and will be indicated at the time of checkout.
                    </div>



                </div>
            
            </div>
        </div>
  
</div>
</template>

<script>
import jQuery  from 'jquery';

export default {
    name: "FaqCompotent",
    props:['language'],
    mounted(){
        jQuery(document).ready(function(){
								
          
            
            jQuery('.faq-category .question').click(function(){
                
                if(jQuery(this).next('.answer').is(':visible'))
                {
                    jQuery(this).next('.answer').slideUp();
                    jQuery(this).removeClass('open');
                }
                else
                {
                    jQuery(this).addClass('open');
                    jQuery(this).next('.answer').slideDown();
                }
                
            });
            
        });
    },
    watch:{
        language:function () {
          
            setTimeout(() => {
                jQuery('.faq-category .question').click(function(){
                
                if(jQuery(this).next('.answer').is(':visible'))
                {
                    jQuery(this).next('.answer').slideUp();
                    jQuery(this).removeClass('open');
                }
                else
                {
                    jQuery(this).addClass('open');
                    jQuery(this).next('.answer').slideDown();
                }
                
            });
            }, 500);
           
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 760px){
    .faq-landing .refund-faq
    {
        width:100% !important;
    }
    .faq-landing .left
    {
        display: none;
    }
    .section.faq {
        padding-left:5% !important;
        padding-right:5% !important;
    }
}
@media screen and (max-width: 1024px){
.faq-landing .left
    {
        display:none !important;
    }
    .faq-landing .refund-faq
    {
        width:100% !important;
    }
}

.faq-landing .refund-faq
{
    width:60%; 
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.faq-landing .left img 
{
    display: inline-block;
    animation: rotating 6s linear infinite; 
}

.faq-landing .left
{
    width:40%;
    text-align: center;
}
.faq-landing 
{
    align-items: center;
    
    display:flex;
}
.question{
    display: flex;
    align-items: center;
    
    border-bottom:0.25px solid black;
}
.question:first-child{
    border-top:0.25px solid black;
}
.section.faq {
    padding-top:0px;
    padding-bottom:250px;
    padding-left:10%;
    padding-right:10%;
}
.question {
    text-transform: none;
    font-size:18px;
    line-height:20px;
}
h2.title img,

.question img {
    margin-right:15px;
    display: inline-block;
}
.faq-category .question.open::after {
   
   
   content: '-';
 

}
.faq-category .question::after {
   line-height: 2;
    font-size:17px;
    position: absolute;
    height: auto;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    transition: all 0.5s;
    content: '+';
    background:transparent;

}
h2.title {
    font-size:70px;
    font-weight: 400;
}
h2.title img {
    position:relative;
    top:5px;
}
</style>