<template>
    <div class="section black">
        <div class="contenudesk">
            <div class="content">
                <div class="top-black">
                    <div class="left">
                        <h2 class="title" v-if="language=='fr'">
                            
                            Une nouvelle génération de lunettes 
                        
                        </h2>
                        <h2 class="title" v-else>

                            Next 
                            generation 
                            eyewear 
                            collections
                            
                        </h2>
                        <br /><br /><br />

                        <div class="titles">
                            <div @click="setSlide(0)" class="title-step"  :class="{active:currentSlide==0}">
                                <span v-if="language=='fr'">Votre Création</span>
                                <span v-else>Your Eyewear Design</span>
                                <span class="line-bottom-black"></span>
                            </div>
                            <div @click="setSlide(1)" class="title-step"  :class="{active:currentSlide==1}">
                                <span v-if="language=='fr'">Sur-Mesure</span>
                                <span v-else>Made to fit</span>
                                <span class="line-bottom-black"></span>
                                
                            </div>
                            <div @click="setSlide(2)" class="title-step" :class="{active:currentSlide==2}">
                                <span v-if="language=='fr'">Imprimée en 3D, à la Demande</span>
                                <span v-else>3D Printed On-Demand</span>
                                <span class="line-bottom-black"></span>
                            </div>
                        </div>
                        <div class="content-left">
                            <div  v-if="currentSlide==0">
                                <br /><br />
                                <div class="number one">
                                    <figure>
                                        <div class="chart-circle" :class="{animate:currentSlide==0}">
                                            <span class="num">1</span>
                                        </div>
                                    </figure>
                                </div>
                            
                                <br /><br />

                                <span v-if="language=='fr'">Une sélection de lunettes entièrement personnalisables.</span>
                                <span v-else>A selection of fully customizable eyewear designs.</span>
                            </div>
                                
                            
                            <div  v-if="currentSlide==1">
                            
                                <br /><br />
                                <div class="number two">
                                    <figure>
                                        <div class="chart-circle" :class="{animate:currentSlide==1}">
                                            <span class="num">2</span>
                                        </div>
                                    </figure>
                                </div>
                                    <br /><br />
                                    <span v-if="language=='fr'">Parfaitement ajustée grâce à notre technologie de scan facial qui analyse les contours de votre visage à partir d’un smartphone.</span>
                                    <span v-else>Tailored through our powerful scanning technology that precisely measure your face using a smartphone.</span>
                            
                                
                            </div>
                            <div  v-if="currentSlide==2">
                            
                                <br /><br />
                                <div class="number three">
                                    <figure>
                                        <div class="chart-circle" :class="{animate:currentSlide==2}">
                                            <span class="num">3</span>
                                        </div>
                                    </figure>
                                </div>
                                    <br /><br />
                                    <span v-if="language=='fr'">Fabriquée à l’unité, de manière durable, pour éviter tout stock et déchet inutile.</span>
                                    <span v-else>Sustainably manufactured on-demand, to prevent unnecessary stock & waste.</span>
                            
                                
                            </div>
                            <br />
                            <a href="/comment-ca-marche" class="readmore" v-if="language=='fr'">En savoir plus</a>
                            <a href="/comment-ca-marche" class="readmore" v-else>Read more</a>
                            
                        </div>


                        
                    </div>
                    <div class="right">
                        <div class="numbers">
                            <div class="number" @click="setSlide(0)"   :class="{active:currentSlide==0}">
                                <span class="num">1</span>
                            </div>

                            <div class="number" @click="setSlide(1)"   :class="{active:currentSlide==1}">
                                <span class="num">2</span>
                            </div>

                            <div class="number" @click="setSlide(2)"  :class="{active:currentSlide==2}">
                                <span class="num">3</span>
                            </div>

                        </div>
                        <div class="img">

                            <video  class="video" v-if="currentSlide==0" style="pointer-events: none;" muted autoplay playsinline loop>
                                <source src="../../../../public/assets/2.mp4" type="video/mp4">
                                No lector
                            </video>

                            <video class="video"   v-if="currentSlide==1" style="pointer-events: none;" muted autoplay playsinline loop>
                                <source src="../../../../public/assets/1.mp4" type="video/mp4">
                                No lector
                            </video>

                            <video  class="video"  v-if="currentSlide==2" style="pointer-events: none;" muted autoplay playsinline loop>
                                <source src="../../../../public/assets/3.mp4" type="video/mp4">
                                No lector
                            </video>
                        
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
        <div class="contenuipad">
            <div class="content">
                <div class="top-black">
                    <div class="left">
                        <h2 class="title" v-if="language=='fr'">
                            
                            Une nouvelle génération de lunettes 
                        
                        </h2>
                        <h2 class="title" v-else>

                            Next 
                            generation 
                            eyewear 
                            collections
                            
                        </h2>
                      
                        <div class="right">
                        <div class="numbers">
                            <div class="number" @click="setSlide(0)"  :class="{active:currentSlide==0}">
                                <span class="num">1</span>
                            </div>

                            <div class="number" @click="setSlide(1)"   :class="{active:currentSlide==1}">
                                <span class="num">2</span>
                            </div>

                            <div class="number" @click="setSlide(2)"   :class="{active:currentSlide==2}">
                                <span class="num">3</span>
                            </div>

                        </div>
                        <div class="img">

                            <video  class="video" v-if="currentSlide==0" style="pointer-events: none;" muted autoplay playsinline loop>
                                <source src="../../../../public/assets/2.mp4" type="video/mp4">
                                No lector
                            </video>

                            <video class="video"   v-if="currentSlide==1" style="pointer-events: none;" muted autoplay playsinline loop>
                                <source src="../../../../public/assets/1.mp4" type="video/mp4">
                                No lector
                            </video>

                            <video  class="video"  v-if="currentSlide==2" style="pointer-events: none;" muted autoplay playsinline loop>
                                <source src="../../../../public/assets/3.mp4" type="video/mp4">
                                No lector
                            </video>
                        
                        </div>
                    </div>


                        <div class="titles">
                            <div class="title-step" @click="setSlide(0)"  :class="{active:currentSlide==0}">
                                <span v-if="language=='fr'">Votre Création</span>
                                <span v-else>Your Eyewear Design</span>
                                <span class="line-bottom-black"></span>
                            </div>
                            <div @click="setSlide(1)"  class="title-step"  :class="{active:currentSlide==1}">
                                <span v-if="language=='fr'">Sur-Mesure</span>
                                <span v-else>Made to fit</span>
                                <span class="line-bottom-black"></span>
                                
                            </div>
                            <div @click="setSlide(2)"  class="title-step" :class="{active:currentSlide==2}">
                                <span v-if="language=='fr'">Imprimée en 3D, à la Demande</span>
                                <span v-else>3D Printed On-Demand</span>
                                <span class="line-bottom-black"></span>
                            </div>
                        </div>
                        <div class="content-left">
                            <div  v-if="currentSlide==0">
                                <br /><br />
                                <div class="number one">
                                    <figure>
                                        <div class="chart-circle" :class="{animate:currentSlide==0}">
                                            <span class="num">1</span>
                                        </div>
                                    </figure>
                                </div>
                            
                                <br /><br />

                                <span v-if="language=='fr'">Une sélection de lunettes entièrement personnalisables.</span>
                                <span v-else>A selection of fully customizable eyewear designs.</span>
                            </div>
                                
                            
                            <div  v-if="currentSlide==1">
                            
                                <br /><br />
                                <div class="number two">
                                    <figure>
                                        <div class="chart-circle" :class="{animate:currentSlide==1}">
                                            <span class="num">2</span>
                                        </div>
                                    </figure>
                                </div>
                                    <br /><br />
                                    <span v-if="language=='fr'">Parfaitement ajustée grâce à notre technologie de scan facial qui analyse les contours de votre visage à partir d’un smartphone.</span>
                                    <span v-else>Tailored through our powerful scanning technology that precisely measure your face using a smartphone.</span>
                            
                                
                            </div>
                            <div  v-if="currentSlide==2">
                            
                                <br /><br />
                                <div class="number three">
                                    <figure>
                                        <div class="chart-circle" :class="{animate:currentSlide==2}">
                                            <span class="num">3</span>
                                        </div>
                                    </figure>
                                </div>
                                    <br /><br />
                                    <span v-if="language=='fr'">Fabriquée à l’unité, de manière durable, pour éviter tout stock et déchet inutile.</span>
                                    <span v-else>Sustainably manufactured on-demand, to prevent unnecessary stock & waste.</span>
                            
                                
                            </div>
                            <br />
                            <a href="/comment-ca-marche" class="readmore" v-if="language=='fr'">En savoir plus</a>
                            <a href="/comment-ca-marche" class="readmore" v-else>Read more</a>
                            
                        </div>


                        
                    </div>
                  
                </div>

                
            </div>
        </div>
        <div class="contenumob">

            <div class="content">
                <div class="top-black">
                    <div class="left">
                        <h2 class="title" v-if="language=='fr'">
                            
                            Une nouvelle génération de lunettes 
                        
                        </h2>
                        
                        <h2 class="title" v-else>

                            Next 
                            generation 
                            eyewear 
                            collections
                            
                        </h2>

                        

                        <br />
                        <div class="img">

                            <video  class="video" v-if="currentSlide==0" style="pointer-events: none;" muted autoplay playsinline loop>
                                <source src="../../../../public/assets/2.mp4" type="video/mp4">
                                No lector
                            </video>

                            <video class="video"   v-if="currentSlide==1" style="pointer-events: none;" muted autoplay playsinline loop>
                                <source src="../../../../public/assets/1.mp4" type="video/mp4">
                                No lector
                            </video>

                            <video  class="video"  v-if="currentSlide==2" style="pointer-events: none;" muted autoplay playsinline loop>
                                <source src="../../../../public/assets/3.mp4" type="video/mp4">
                                No lector
                            </video>

                        </div>

                        <br />

                   


                        <div class="content-left">
                            <div class="title-step" @click="setSlide(0)"  :class="{active:currentSlide==0}">
                                <span v-if="language=='fr'">Votre Création</span>
                                <span v-else>Your Eyewear Design</span>
                                <span class="line-bottom-black"></span>
                            </div>

                            <div>
                                <br />
                                <div class="number one">
                                    <figure>
                                        <div class="chart-circle" :class="{animate:currentSlide==0}">
                                            <span class="num">1</span>
                                        </div>
                                    </figure>
                                </div>
                            

                                <span v-if="language=='fr'">Une sélection de lunettes entièrement personnalisables.</span>
                                <span v-else>A selection of fully customizable eyewear designs.</span>
                            </div>
                                
                            <div class="title-step" @click="setSlide(1)"  :class="{active:currentSlide==1}">
                                <span v-if="language=='fr'">Sur-Mesure</span>
                                <span v-else>Made to fit</span>
                                <span class="line-bottom-black"></span>
                                
                            </div>
                            
                            <div>
                            
                                <br />
                                <div class="number two">
                                    <figure>
                                        <div class="chart-circle" :class="{animate:currentSlide==1}">
                                            <span class="num">2</span>
                                        </div>
                                    </figure>
                                </div>
                                    
                                    <span v-if="language=='fr'">Parfaitement ajustée grâce à notre technologie de scan facial qui analyse les contours de votre visage à partir d’un smartphone.</span>
                                    <span v-else>Tailored through our powerful scanning technology that precisely measure your face using a smartphone.</span>
                            
                                
                            </div>

                            <div class="title-step" @click="setSlide(2)"  :class="{active:currentSlide==2}">
                                <span v-if="language=='fr'">Imprimée en 3D, à la Demande</span>
                                <span v-else>3D Printed On-Demand</span>
                                <span class="line-bottom-black"></span>
                            </div>

                            <div>
                            
                                <br />
                                <div class="number three">
                                    <figure>
                                        <div class="chart-circle" :class="{animate:currentSlide==2}">
                                            <span class="num">3</span>
                                        </div>
                                    </figure>
                                </div>
                                    
                                    <span v-if="language=='fr'">Fabriquée à l’unité, de manière durable, pour éviter tout stock et déchet inutile.</span>
                                    <span v-else>Sustainably manufactured on-demand, to prevent unnecessary stock & waste.</span>
                            
                                
                            </div>
                            <br />
                            <a href="/comment-ca-marche" class="readmore" v-if="language=='fr'">En savoir plus</a>
                            <a href="/comment-ca-marche" class="readmore" v-else>Read more</a>
                            
                        </div>


                        <br /><br /><br />
                    </div>
                
                </div>

                
            </div>
        </div>
  </div>
</template>

<script>
import jQuery  from 'jquery';
export default {
  name: 'HomeBlackComponentLanding',
  props:["language","scrolled"],
  
  data(){
     return {
        firstElem:true,
        secondElem:true,
        thirdElem:true,
        currentSlide:null,
        intervalSlider:null,
        intervalInit:false,
     } 
  },
  methods: {
      nextSlide() {
        let nextslide=this.currentSlide
        nextslide+=1
        clearInterval(this.intervalSlider)
        if(nextslide>2)
        {
            nextslide=0

        }
        if(nextslide==0)
        {
            this.intervalSlider = setInterval(this.nextSlide, 11000);
        }
        else if(nextslide==1)
        {
            this.intervalSlider = setInterval(this.nextSlide, 4500);
        }
        else if(nextslide==2)
        {
            this.intervalSlider = setInterval(this.nextSlide, 7000);
        }
        this.currentSlide=nextslide

      },
      setSlide(slide) {
        let nextslide=slide
     
        clearInterval(this.intervalSlider)
        if(nextslide>2)
        {
            nextslide=0

        }
        if(nextslide==0)
        {
            this.intervalSlider = setInterval(this.nextSlide, 11000);
        }
        else if(nextslide==1)
        {
            this.intervalSlider = setInterval(this.nextSlide, 4500);
        }
        else if(nextslide==2)
        {
            this.intervalSlider = setInterval(this.nextSlide, 7000);
        }
        this.currentSlide=nextslide

      }

  },
  beforeUnmount(){
  
    //window.CSS.removeProperty("--p");
    clearInterval(this.intervalSlider);
  },
  mounted() {

 
    if(this.scrolled==true)
    {
        if(this.intervalInit==false)
        {
            this.currentSlide=0
            this.intervalInit=true
            this.intervalSlider = setInterval(this.nextSlide, 11000);
        }
    }
    

    
    

    // When you want to cancel it:
    //clearInterval(this.intervalSlider);
  },
  updated() {

 
    if(this.scrolled==true)
    {

        
        if(this.intervalInit==false)
        {
            this.intervalInit=true
            this.currentSlide=0
            this.intervalSlider = setInterval(this.nextSlide, 11000);
        }
    }





    // When you want to cancel it:
    //clearInterval(this.intervalSlider);
    }

}
</script>

<style scoped>
@keyframes animcircletest {
    0%{
        --p: 0%;
    }
    100% {
        --p: 100%;
    }
}
.chart-circle {
  width: 40px; 
  height: 40px; 
  display: inline-block;
  margin-right:10px;
  aspect-ratio: 1.5;
  border-radius: 50%;
  --p: 0%; /*fallback*/
  background: conic-gradient(#fff var(--p), #797979 0); 
  position: relative;
  
}
.one .chart-circle.animate{
  animation : animcircletest 11s linear;
  /* transition: --p 3s linear;
  --p: 100%; */
}
.two .chart-circle.animate{
  animation : animcircletest 4.5s linear;
  /* transition: --p 3s linear;
  --p: 100%; */
}
.three .chart-circle.animate{
  animation : animcircletest 7s linear;
  /* transition: --p 3s linear;
  --p: 100%; */
}
.chart-circle::before {
  position: absolute;
  inset:2px; 
  content: '';
  border-radius: inherit;
  background: #000;
}

.content-left .number {
    border:0;
}

.content-left 
{
    font-size: 26px;
    width:60%;
    min-height: 500px;
}

.section.black .content {
    display:flex;
}
.readmore 
{
    margin-top:20px;
    border:1px solid #aaa;
    font-weight: 600;
    border-radius: 10px;
    color:#fff;
    font-size:18px;
    text-decoration: none;
    padding:10px 30px;
    display:inline-block;
}

.top-black .left 
{
   
    z-index: 2;
    width:50%;
 
}
@keyframes fadein {
    0%{
        opacity:0;
    
    }
    100%{
        opacity:1;
    
    }

}

.img .video {
    position:absolute;
    top:50%;
    z-index: 1;
    min-width:100%;
    max-width:500%;
    max-height:500%;
    min-height:100%;
    left:50%;
    transform: translate(-50%,-50%);
    animation: linear fadein 0.5s;
}
.img video {
    position:absolute;
    top:50%;
    z-index: 1;
    min-width:100%;
    max-width:500%;
    max-height:500%;
    min-height:100%;
    left:50%;
    transform: translate(-50%,-50%);
    animation: linear fadein 0.5s;
}

.img {
    height:100%;
    overflow: hidden;
    border-radius: 10px;
    margin-left:0%;
    min-height:30vw;
    position:relative;
    width:100%;
    background:rgba(255,255,255,0.1);
}
.section.black {
    background:#000;
    width:100%;
    padding-left:5%;
    padding-right:5%;
    padding-top:150px;
    padding-bottom:250px;
}
.title-step .line-bottom-black
{
    opacity: 0;
    position:absolute;
    bottom:0;
    width:100%;
    left:0;
    height:2px;
    background:#fff;
    
}
.title-step.active .line-bottom-black
{
    opacity: 1;

}
.left .titles .title-step
{
    cursor:pointer;
}
.title-step
{
    position: relative;
    padding:20px;
    border-bottom:1px solid #ccc;
}
.left .titles 
{
   
    display:flex;
    font-size:26px;
    max-width: 90%;
    width:auto;
}
.right{
    width:50%;
}
.number.active
{
    background:#fff;
    color:#000;
}
.number .num 
{
    display: block;
    line-height: 0.1;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    font-size:15px;
}
.numbers .number 
{
    cursor:pointer;
}
.number
{
    margin-right:20px;
    width:40px;
    height:40px;
    position: relative;
    border:1px solid #fff;
    border-radius:50%;
    background:transparent;
    color:#fff;
}
.right .numbers 
{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom:30px;
    text-align: right;
}
.top-black {
    color:#fff;
    display: flex;
    position: relative;
    width:100%;
    align-items:stretch;
    justify-content: space-between;
}
h2.title {
    
    font-weight: 400;
    font-style: normal;
    line-height:62px;
    padding-right:30px;
    font-size:60px;
    padding-top:0;
    color:#fff;
    margin-top:0;
    text-transform: none;
}

.contenuipad 
{
    display: none;
}
@media screen and (min-width:1024px) and (max-width:1290px)
{
    h2.title
    {
        font-size:50px !important;
        line-height: 52px !important;
    }
    .left .titles .title-step 
    {
        padding-left:10px !important;
        padding-right:10px !important;
    }
    .left .titles
    {
        font-size:18px !important;
    }
    .left
    {
        width:60% !important;
    }
    .right
    {
        width:40% !important;
    }
}
@media screen and (min-width:1290px) and (max-width:1480px)
{
    h2.title
    {
        font-size:50px !important;
        line-height: 52px !important;
    }
    .left .titles
    {
        font-size:22px !important;
    }
    .left
    {
        width:60% !important;
    }
    .right
    {
        width:40% !important;
    }
}
@media screen and (min-width:1480px) and (max-width:1780px)
{

    .left
    {
        width:60% !important;
    }
    .right
    {
        width:40% !important;
    }
}
@media screen and (max-width:1024px)
{
    .left .titles
    {
        font-size:22px;
    }
    .contenudesk 
    {
        display: none !important;
    }
    
    .contenuipad
    {
        display: block !important;
    }
    .section.black .content .table-bottom
    {
        margin-top:20px;
    }
    .section.black .content .table-bottom,.section.black .content .top-black
    {
        width:100%;
    }
    .section.black .content
    {
        flex-direction: column;
    }
   

}
@media screen and (max-width:760px)
{
    .contenuipad 
{
    display: none !important;
}
    .numbers
    {
        display:none !important;
    }
    .readmore
    {
        font-size:15px !important;
    }
    .title-step
    {
        font-size:22px !important;
        width:80% !important;
        padding-left: 0 !important;
    }
    .chart-circle
    {
        width:30px !important;
        height:30px !important;
    }
    .number .num
    {
        font-size:10px !important;
    }
    .content-left
    {
        font-size:15px !important;
        width:90% !important;
    }
    .section.black .content .table-bottom,.section.black .content .top-black
    {
        width:100%;
    }
    .section.black .content
    {
        flex-direction: column;
    }
   
    .img {
        height:350px !important;
    }
    .section.black h2.title
    {
        font-size: 38px !important;
        line-height: 40px;
    }
    .top-black
    {
        padding-left:5%;
        padding-right:5%;
    }
    .section.black {
    padding-left:0;
    padding-right:0;
    padding-top:30px !important;
    padding-bottom:30px !important;
    }
   
    .top-black .left {
        width:100%;
        position: relative;
    }
   
    .img 
    {
        width:100%;
        margin-left:0;
    }
    .top-black .right {
        width:100%;
    }
    .top-black {
        
        flex-direction: column;
    }
}

</style>